export const isEmail = (email: string) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export const validJson = (val: string) => {
  try {
    return JSON.parse(val);
  } catch (error) {
    return false;
  }
};
