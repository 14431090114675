export const TextValidation = function (type: string, value: any): boolean {
  switch (type) {
    case "email":
      if (
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          value
        )
      ) {
        return true;
      }
      if (value.trim() === "") {
        return false;
      }
      return false;
    case "number":
      if (/[+-]?([0-9]*[.])?[0-9]/.test(value)) {
        return true;
      } else return false;
    default:
      if (value !== "" && value.length > 0 && value.trim() !== "") {
        return true;
      } else {
        return false;
      }
  }
};
